import React from "react";
import About from "../componets/About";

const AboutPage = () => {
  return (
    <>
      <div className="pt-5 pb-5">
        <About />
      </div>
    </>
  );
};

export default AboutPage;
